import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    ranksInfo: {
      type: Object,
      required: true
    },
    isComponentModalActive: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const closeGame = () => {
      window.liff.closeWindow()
    }

    return {
      closeGame
    }
  },
})